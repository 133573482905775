export function run(configuration) {
  jQuery(document).on("click", "a", (event) => {
    if (!document.body.classList.contains("iframed")) return

    const element =
      (event.target && event.target.href && event.target) ||
      (event.currentTarget && event.currentTarget.href && event.currentTarget)

    if (elementShouldBeOpenedInANewTab(element)) {
      event.preventDefault()
      window.open(element.href, "_blank")
    }
  })

  function elementShouldBeOpenedInANewTab(element) {
    const { href } = element

    if (!href) return false

    if (
      configuration.embedded_donation_form_link_urls_to_open_in_new_tabs.find((url) =>
        href.startsWith(url)
      )
    ) {
      return true
    }

    if (!href.startsWith(configuration.church_center.giving_url)) return true

    if (element.dataset.turbolinks === "false") return true

    return false
  }
}

export default exports
