import StripeWithGuard from "shared/runners/stripe_with_guard"
import StripeSetupIntents from "church_center/runners/stripe_setup_intents"

let stripe

export function run() {
  StripeWithGuard.promise().then(() => {
    stripe = StripeWithGuard.instance()
  })
}

export function connectBankAccount({
  onSuccess,
  onExit,
  onError,
  personName,
  personAccountCenterId,
  personEmailAddress,
}) {
  StripeSetupIntents.fetchClientSecret({
    personAccountCenterId,
    personEmailAddress,
  }).then((clientSecret) => {
    collectBankAccountDetails({ clientSecret, onSuccess, onExit, onError, personName })
  })
}

export function confirmBankAccount({ clientSecret, onSuccess, onError }) {
  stripe
    .confirmUsBankAccountSetup(clientSecret, { expand: ["payment_method"] })
    .then(({ setupIntent, error }) => {
      if (error) {
        onError(error)
      } else if (setupIntent.status === "requires_payment_method") {
        onError({
          message:
            "We were unable to confirm your payment method. Please try again with a different method.",
        })
      } else if (
        setupIntent.status === "succeeded" ||
        (setupIntent.next_action && setupIntent.next_action.type === "verify_with_microdeposits")
      ) {
        onSuccess({ setupIntent })
      }
    })
}

function collectBankAccountDetails({ clientSecret, onSuccess, onExit, onError, personName }) {
  stripe
    .collectBankAccountForSetup({
      clientSecret,
      params: {
        payment_method_type: "us_bank_account",
        payment_method_data: {
          billing_details: { name: personName },
        },
      },
      expand: ["payment_method"],
    })
    .then((result) => {
      const { setupIntent, error } = result
      if (error) {
        onError(error)
      } else if (setupIntent.status === "requires_payment_method") {
        onExit()
      } else if (setupIntent.status === "requires_confirmation") {
        onSuccess({ setupIntent })
      }
    })
}

export default exports
