export function run() {
  const fixClassName = "modal--open--but-on-html-for-mobile-scroll-bugz"

  jQuery(document).on({
    "modal:load": () => jQuery("html").addClass(fixClassName),
    "modal:hide": () => jQuery("html").removeClass(fixClassName),
  })
}

export default exports
