import ChooseNextDonation from "shared/components/choose_next_donation"
import RepeatableScheduleInput from "shared/components/repeatable_schedule_input"
import RepeatableSchedules from "shared/runners/repeatable_schedules"
import OccurrenceApi from "church_center/api/occurrence"
import moment from "moment"
import React from "react"
import { bool, func, object, string } from "prop-types"

const getStartDates = OccurrenceApi.startDates.bind(OccurrenceApi)

export default class RecurringFrequencyInput extends React.Component {
  static propTypes = {
    isRecurring: bool.isRequired,
    onChange: func.isRequired,
    schedule: object.isRequired,
    next_occurrence: string,
  }

  handleChangeIsRecurring = ({ target: { value } }) => {
    const isRecurring = value === "true"
    const { schedule } = this.props
    this.props.onChange({ isRecurring, schedule })
  }

  handleChangeSchedule = (schedule) => {
    this.props.onChange({ isRecurring: true, next_occurrence: null, schedule })
  }

  handleChangeNextOccurrence = (next_occurrence) => {
    const { schedule, isRecurring, onChange } = this.props
    const frequency = RepeatableSchedules.getFrequency(this.props.schedule)

    let nextSchedule = schedule

    if (frequency === "biweekly") {
      const start_after = moment(next_occurrence).subtract({ days: 1 }).format("YYYY-MM-DD")
      nextSchedule = _.merge({}, schedule, { biweekly: { start_after } })
    }

    onChange({ isRecurring, schedule: nextSchedule, next_occurrence })
  }

  getToday = () => {
    return moment().format("YYYY-MM-DD")
  }

  render() {
    return (
      <div className="mt-4">
        <label htmlFor="donation_form_is_recurring" className="label h4">
          Frequency
        </label>

        <div>
          <select
            id="donation_form_is_recurring"
            value={this.props.isRecurring}
            onChange={this.handleChangeIsRecurring}
            className="select"
            data-cy="is_recurring_select"
          >
            <option value={false}>One time</option>
            <option value={true}>Regularly</option>
          </select>
          {this.props.isRecurring && (
            <div>
              <RepeatableScheduleInput
                schedule={this.props.schedule}
                onChange={this.handleChangeSchedule}
              />
              <ChooseNextDonation
                schedule={this.props.schedule}
                next_occurrence={this.props.next_occurrence}
                getStartDates={getStartDates}
                onChange={this.handleChangeNextOccurrence}
                label="My first donation will be:"
              />
            </div>
          )}
        </div>
      </div>
    )
  }
}
