import { designationsAmountToNumber } from "church_center/utils/designations"
import appConfig from "shared/runners/app_config"

export function getFeeRates(paymentMethodType) {
  if (!appConfig.fee_rates) return {}

  if (paymentMethodType === "card") {
    return appConfig.fee_rates.card
  } else {
    return appConfig.fee_rates.bank_account
  }
}

function calculateTotalCents(designations) {
  return parseInt(designationsAmountToNumber(designations) * 100, 10)
}

function calculateGrossForDesiredNet(desiredNetCents, paymentMethodType) {
  const fees = getFeeRates(paymentMethodType)
  return Math.round((desiredNetCents + fees.flat_cents) / (1 - fees.percentage))
}

function percentOfDonation(designation, allDesignations) {
  return parseFloat(designation.amount) / designationsAmountToNumber(allDesignations)
}

function recalculateDesignationAmounts(feeCents, designations) {
  const nextDesignations = _.cloneDeep(designations)

  nextDesignations.reduce((remainingFeeCents, designation, index) => {
    const partialAmountCents = Math.round(feeCents * percentOfDonation(designation, designations))

    if (nextDesignations.length === index + 1) {
      designation.amount = (parseFloat(designation.amount) + remainingFeeCents / 100).toString()
      return 0
    } else if (partialAmountCents >= remainingFeeCents) {
      designation.amount = (parseFloat(designation.amount) + remainingFeeCents / 100).toString()
      return 0
    } else {
      designation.amount = (parseFloat(designation.amount) + partialAmountCents / 100).toString()

      return remainingFeeCents - partialAmountCents
    }
  }, feeCents)

  return nextDesignations
}

export function calculate(designations, paymentMethodType) {
  const totalCents = calculateTotalCents(designations)
  const newGrossCents = calculateGrossForDesiredNet(totalCents, paymentMethodType)
  const coveredFeeCents = newGrossCents - totalCents

  return {
    designationsCoveringFee: recalculateDesignationAmounts(coveredFeeCents, designations),
    coveredFeeCents,
  }
}

export default exports
