import React from "react"
import ErrorMessage from "church_center/components/shared/error_message"
import FindPaymentMethod from "church_center/components/find_payment_method"
import SubmitPaymentMethodForm from "church_center/components/payment_method_form/submit_payment_method_form"
import { ccoPerson } from "shared/utils/prop_types"
import { bool, shape, string } from "prop-types"

PaymentMethodForm.propTypes = {
  accept_apple_pay: bool.isRequired,
  ach_allowed: bool.isRequired,
  card_giving_level: string.isRequired,
  error: string,
  logged_in_person: ccoPerson,
  organization: shape({
    country: string.isRequired,
    currency: string.isRequired,
    name: string.isRequired,
  }).isRequired,
  routes: shape({
    create: string.isRequired,
  }).isRequired,
  is_donating: bool,
}

export default function PaymentMethodForm(props) {
  const paymentMethods = []
  const applePay = {
    manualLineItems: [
      {
        label: "Example (will not be charged)",
        amount: "0.99",
        type: "final",
      },
    ],
    manualTotalAmount: "0.99",
    organizationName: props.organization.name,
  }

  return (
    <FindPaymentMethod
      acceptApplePay={props.accept_apple_pay}
      applePay={applePay}
      cardGivingLevel={props.card_giving_level}
      country={props.organization.country}
      currency={props.organization.currency}
      achAllowed={props.ach_allowed}
      paymentMethods={paymentMethods}
      person={props.logged_in_person}
      unhandledGivingError={props.error}
      isDonating={props.is_donating}
    >
      {({
        paymentMethod,
        requestToken,
        loading,
        submitUnverifiedPaymentMethod,
        verificationMethod,
        autoSubmit,
      }) => {
        const manualMicrodeposit = "stripe_microdeposit" === verificationMethod

        let submitText = "Add payment method"

        if (submitUnverifiedPaymentMethod) {
          submitText = "Start bank verification"
          if (manualMicrodeposit) {
            submitText += " & email me instructions"
          }
        }

        return (
          <div>
            {!loading && <ErrorMessage>{props.error}</ErrorMessage>}
            <SubmitPaymentMethodForm
              loading={loading}
              onRequestToken={requestToken}
              paymentMethod={paymentMethod}
              submitText={submitText}
              url={props.routes.create}
              autoSubmit={autoSubmit}
            />
          </div>
        )
      }}
    </FindPaymentMethod>
  )
}
