import React from "react"
import { shape, string, object, oneOfType, number } from "prop-types"
import { HashFields, HiddenField } from "react-rails-form-helpers"
import {
  STRIPE_BANK_SUCCESS,
  STRIPE_CARD_SUCCESS,
  USING_STORED_PAYMENT_METHOD,
} from "church_center/components/find_payment_method/legacy_find_payment_method"
import { STRIPE_PAYMENT_ELEMENT_STRATEGY } from "church_center/components/find_payment_method"

import { isGivingPaymentMethodId, isStripeSetupIntentId } from "church_center/utils/stripe"

PaymentMethodFormFields.propTypes = {
  paymentMethod: shape({
    strategy: string.isRequired,
    payload: object.isRequired,
    paymentMethodId: oneOfType([number, string]),
  }).isRequired,
}

function getUntilSomethingIsGotten(topic, ...getters) {
  return _(getters)
    .map((g) => _.get(topic, g))
    .compact()
    .head()
}

function PaymentMethodFormFields({ paymentMethod }) {
  if (paymentMethod.strategy === STRIPE_PAYMENT_ELEMENT_STRATEGY) {
    if (isGivingPaymentMethodId(paymentMethod.paymentMethodId)) {
      return (
        <HashFields name="stripe">
          <HiddenField name="payment_method_id" value={paymentMethod.paymentMethodId} />
        </HashFields>
      )
    } else if (isStripeSetupIntentId(paymentMethod.paymentMethodId)) {
      return (
        <HashFields name="stripe">
          <HiddenField name="setup_intent" value={paymentMethod.paymentMethodId} />
        </HashFields>
      )
    } else {
      return null
    }
  } else {
    const token = getUntilSomethingIsGotten(paymentMethod.payload.response, "paymentMethod.id")

    return (
      <div>
        {paymentMethod.strategy === USING_STORED_PAYMENT_METHOD && (
          <HashFields name="stripe">
            <HiddenField name="payment_method_id" value={paymentMethod.payload.payment_method_id} />
          </HashFields>
        )}

        {paymentMethod.strategy === STRIPE_BANK_SUCCESS && (
          <HashFields name="stripe">
            <HiddenField name="token" value={token} />
            <HiddenField
              name="setup_intent"
              value={_.get(paymentMethod.payload.response, "setupIntent.id")}
            />
          </HashFields>
        )}

        {paymentMethod.strategy === STRIPE_CARD_SUCCESS && (
          <HashFields name="stripe">
            <HiddenField name="token" value={token} />
          </HashFields>
        )}
      </div>
    )
  }
}

export default PaymentMethodFormFields
