export function run(configuration) {
  // Because we can't write to the church_center_session we make a request to the proper app to
  // keep the cookie updated.
  jQuery(window).on("load", () => jQuery.get("/sessions/check"))
  jQuery(document).on("turbolinks:load", () => jQuery.get("/sessions/check"))

  jQuery(document).on("click", "[data-churchcenter-logout-destination]", (event) => {
    event.preventDefault()
    const element = jQuery(event.target)

    jQuery.get(element.attr("href")).then(() => {
      window.location = element.data("churchcenter-logout-destination")
    })
  })
}

export default exports
