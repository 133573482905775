/* eslint-disable react/prefer-es6-class */

import React from "react"
import createReactClass from "create-react-class"
import { number, string } from "prop-types"

export default createReactClass({
  displayName: "RevealMoreChildren",

  propTypes: {
    begin: number,
    show_more_text: string,
    show_more_class: string,
    __html: string,
  },

  getDefaultProps() {
    return { begin: 2 }
  },

  getInitialState() {
    return { revealed: this.props.begin >= this.getTotalChildrenCount() }
  },

  handleClick() {
    this.setState({ revealed: true })
  },

  getDangerousHTMLNode() {
    const div = document.createElement("div")
    div.innerHTML = this.props.__html
    return div
  },

  getDangerousHTMLString() {
    const div = this.getDangerousHTMLNode()

    if (!this.state.revealed) {
      for (let i = this.props.begin, length = div.children.length; i < length; i++) {
        div.children[i].style.display = "none"
      }
    }

    return div.innerHTML
  },

  getHiddenChildrenCount() {
    return this.getTotalChildrenCount() - this.props.begin
  },

  getTotalChildrenCount() {
    return this.getDangerousHTMLNode().children.length
  },

  render() {
    const showMoreText = (
      <span>
        <span className="count">{this.getHiddenChildrenCount()}</span>{" "}
        <span className="text">more</span>
        <span className="ellipsis">…</span>
      </span>
    )

    const showMoreTrigger = this.state.revealed ? null : (
      <div className="reveal-more-trigger">
        <a onClick={this.handleClick} className={this.props.show_more_class} tabIndex="0">
          {this.props.show_more_text || showMoreText}
        </a>
      </div>
    )

    return (
      <div>
        <div dangerouslySetInnerHTML={{ __html: this.getDangerousHTMLString() }} />
        {showMoreTrigger}
      </div>
    )
  },
})
