import React from "react"
import ReactDOM from "react-dom"
import { UNRESOLVED } from "church_center/components/find_payment_method/legacy_find_payment_method"
import { STRIPE_PAYMENT_ELEMENT_STRATEGY } from "church_center/components/find_payment_method"
import { nondeterministicRedirect } from "church_center/runners/handle_nondeterministic_donation_form_error"
import { func, object, bool } from "prop-types"
import { featureEnabled } from "shared/runners/flipper"

export default function findPaymentMethodTokenResolver(Lower) {
  return class extends React.Component {
    static propTypes = {
      onRequestToken: func.isRequired,
      paymentMethod: object.isRequired,
      loading: bool,
      redirectOnNondeterministicAjaxError: bool,
    }

    static defaultProps = {
      loading: false,
      redirectOnNondeterministicAjaxError: false,
    }
    state = { loading: false }

    componentDidUpdate(prevProps) {
      if (this.props.paymentMethod.strategy === STRIPE_PAYMENT_ELEMENT_STRATEGY) {
        return
      }

      const wasUnresolved = prevProps.paymentMethod.strategy === UNRESOLVED
      const nowIsResolved = this.props.paymentMethod.strategy !== UNRESOLVED
      if (wasUnresolved && nowIsResolved) {
        Rails.fire(ReactDOM.findDOMNode(this), "submit")
      }
    }

    handleSubmit = (event) => {
      if (event) {
        event.preventDefault()
        event.stopPropagation()
      }

      if (this.props.paymentMethod.strategy === STRIPE_PAYMENT_ELEMENT_STRATEGY) {
        this.props.onRequestToken().then((success) => {
          if (success) {
            this.handleRailsRemoteSubmit(event)
          }
        })
      } else if (this.props.paymentMethod.strategy === UNRESOLVED) {
        this.props.onRequestToken()
      } else if (this.props.paymentMethod.payload.applePay) {
        this.handleApplePaySubmit()
      } else {
        this.handleRailsRemoteSubmit(event)
      }
    }

    // handleApplePaySubmit exists because handleRailsRemoteSubmit will not work...
    // When Safari is rendering the Apple Pay sheet, all DOM interactions/manipulations are
    // blocked. This means that React cannot update the <Lower /> form on the page, to correctly
    // render the payment method form fields. By rendering the same form into a container that is
    // not attached to the DOM, we are able to determine all our form data, submit it manually,
    // and the results are the same.
    handleApplePaySubmit = () => {
      const completion = this.props.paymentMethod.payload.applePay.completion
      const element = <Lower {...this.props} />
      const container = document.createElement("div")
      const success = "success"
      const failure = "fail"

      ReactDOM.render(element, container, () => {
        const form = jQuery(container).find("form").get(0)
        const data = jQuery(form).serialize()
        jQuery.ajax({
          type: "POST",
          url: form.action,
          data,
          success: () => completion(success),
          error: (jqxhr) => {
            const responseStatus = jqxhr.status
            completion(failure)
            if (responseStatus !== 422 && this.props.redirectOnNondeterministicAjaxError) {
              nondeterministicRedirect({
                href: form.action,
                responseStatus,
              })
            }
          },
        })
      })
    }

    handleRailsRemoteSubmit = (submitEvent) => {
      this.setState({ loading: true }, () => {
        Rails.handleRemote.call(submitEvent.target, submitEvent.nativeEvent)
        const form = ReactDOM.findDOMNode(this)

        jQuery(form).one("ajax:error", (event) => {
          const [_body, _textStatus, jqxhr] = event.detail

          const responseStatus = jqxhr.status
          if (
            responseStatus !== 422 &&
            responseStatus !== 429 &&
            this.props.redirectOnNondeterministicAjaxError
          ) {
            event.preventDefault()
            event.stopPropagation()
            nondeterministicRedirect({
              href: event.target.action,
              responseStatus,
            })
          } else {
            this.setState({ loading: false })
          }
        })
      })
    }

    render() {
      const combinedProps = {
        ...this.props,
        loading: this.props.loading || this.state.loading,
        onSubmit: this.handleSubmit,
      }

      return <Lower {...combinedProps} />
    }
  }
}
