import { run as runFlipper } from "shared/runners/flipper"
import sessionVerifier from "church_center/runners/session_verifier"
import stripeWithGuard from "shared/runners/stripe_with_guard"
import stripeConnections from "church_center/runners/stripe_connections"
import stripeSetupIntents from "church_center/runners/stripe_setup_intents"
import { run as runI18n } from "shared/runners/i18n"
import { run as runRepeatableSchedules } from "shared/runners/repeatable_schedules"
import { run as configureNondeterministicRedirect } from "church_center/runners/handle_nondeterministic_donation_form_error"
import { run as runAppConfig } from "shared/runners/app_config"

export function run(configuration) {
  const {
    flipper,
    i18n,
    stripe,
    stripe_setup_intents,
    repeatable,
    nondeterministic_donation_form_error_redirect_url,
    ...rest
  } = configuration
  runFlipper(flipper)
  runI18n(i18n)
  sessionVerifier.run(configuration)
  stripeWithGuard.run(stripe)
  stripeConnections.run()
  stripeSetupIntents.run(stripe_setup_intents)
  configureNondeterministicRedirect(nondeterministic_donation_form_error_redirect_url)
  runRepeatableSchedules(repeatable)
  runAppConfig(rest)
}

export default exports
