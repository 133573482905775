import React, { useEffect, useRef } from "react"
import ReactDOM from "react-dom"
import { FormFor } from "react-rails-form-helpers"
import Ladda from "shared/components/ladda"
import PaymentMethodFormFields from "church_center/components/donation_form/payment_method_form_fields"
import findPaymentMethodTokenResolver from "church_center/components/find_payment_method/find_payment_method_token_resolver"
import { bool, func, shape, string, object } from "prop-types"
import { featureEnabled } from "shared/runners/flipper"

SubmitPaymentMethodForm.propTypes = {
  loading: bool.isRequired,
  onSubmit: func.isRequired,
  paymentMethod: shape({
    strategy: string.isRequired,
    payload: object.isRequired,
  }).isRequired,
  submitText: string.isRequired,
  url: string.isRequired,
  autoSubmit: bool,
}

function SubmitPaymentMethodForm({
  loading,
  onSubmit,
  paymentMethod,
  submitText,
  url,
  autoSubmit,
}) {
  const formRef = useRef()

  useEffect(() => {
    if (autoSubmit === true) {
      const form = $(ReactDOM.findDOMNode(formRef.current)).closest("form").get(0)
      Rails.fire(form, "submit")
    }
  }, [autoSubmit])

  return (
    <FormFor url={url} onSubmit={onSubmit} data-remote ref={formRef}>
      <PaymentMethodFormFields paymentMethod={paymentMethod} />
      <div className="mt-3 ta-c">
        <Ladda
          button
          loading={loading}
          className={`btn primary-btn ${loading ? "disabled-btn" : null}`}
          disabled={loading}
        >
          {submitText}
        </Ladda>
      </div>
    </FormFor>
  )
}

export default findPaymentMethodTokenResolver(SubmitPaymentMethodForm)
