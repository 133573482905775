import React, { Fragment } from "react"
import { shape, string, arrayOf, bool, func } from "prop-types"
import styled from "@emotion/styled"
import Icon from "church_center/components/external_icon"
import colors from "church_center/utils/colors"

const churchProp = shape({
  avatar_url: string,
  cco_contact_website: string.isRequired,
  cco_phone_number: string.isRequired,
  destination_url: string.isRequired,
  name: string.isRequired,
})

ChurchItem.propTypes = {
  avatar_url: string,
  cco_contact_website: string,
  cco_phone_number: string,
  destination_url: string,
  name: string,
}

const ChurchListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (min-width: 480px) {
    flex-direction: row;
  }
`

const ChurchLink = styled.a`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid ${colors.tint5};
  border-radius: 4px;
  transition: 0.2s background ease;
  padding: 1em;
  margin-bottom: 1em;
  line-height: 1.3;
  flex-wrap: wrap;
  text-align: center;
  min-height: 220px;

  @media (min-width: 480px) {
    width: calc(50% - 0.5em);
  }

  &:hover {
    background: ${colors.tint9};
  }
`

const ChurchLinkAvatar = styled.div`
  max-width: 150px;
  max-height: 150px;
  margin: 0 auto 0.75em auto;

  img {
    max-width: 150px;
    max-height: 150px;
    display: block;
  }
`

const ChurchLinkText = styled.div`
  max-width: 100%;
  display: block;

  div {
    text-align: center;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const ChurchAvatarPlaceholder = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: ${colors.tint7};
  width: 100px;
  height: 100px;
  border-radius: 100px;
  margin: 0 auto;
  color: ${colors.tint4};
  text-transform: uppercase;
  font-size: 3.5em;
`

const EmptyResultsContainer = styled.div`
  display: block;
  background: ${colors.tint8};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.tint1};
  border-radius: 4px;
  padding: 1em;
`

const StyledClearButton = styled.button`
  background: ${colors.tint6};
  border: none;
  height: 24px;
  width: 24px;
  border-radius: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 1;
  transition: 0.2s background ease;
  padding: 0;
  cursor: pointer;

  &:hover {
    background: ${colors.tint7};
  }

  svg {
    fill: ${colors.tint1};
    height: 10px;
    width: 10px;
    margin: 0;
    padding: 0;
    display: block;
  }
`

ClearButton.propTypes = {
  onClick: func.isRequired,
}

function ClearButton({ onClick }) {
  return (
    <StyledClearButton onClick={onClick} type="button">
      <Icon symbol="general#x" />
    </StyledClearButton>
  )
}

function ChurchItem({ avatar_url, cco_contact_website, cco_phone_number, destination_url, name }) {
  return (
    <ChurchLink href={destination_url} alt={name}>
      <ChurchLinkAvatar>
        {avatar_url ? (
          <img src={`${avatar_url}?g=300x300#`} />
        ) : (
          <ChurchAvatarPlaceholder>{name.charAt(0)}</ChurchAvatarPlaceholder>
        )}
      </ChurchLinkAvatar>
      <ChurchLinkText>
        <div className="fw-600">{name}</div>
        <div className="c-tint3 fs-4">
          {cco_contact_website ? cco_contact_website : cco_phone_number}
        </div>
      </ChurchLinkText>
    </ChurchLink>
  )
}

class ChurchList extends React.Component {
  static propTypes = {
    churches: arrayOf(churchProp),
    showEmptyResults: bool,
    listTitle: string,
    searchTerm: string,
    resetSearchAndFilteredResults: func,
  }

  render() {
    const {
      churches,
      listTitle,
      showEmptyResults,
      searchTerm,
      resetSearchAndFilteredResults,
    } = this.props

    if (churches.length === 0 && !showEmptyResults) {
      return <div />
    }

    return (
      <div className="mt-2">
        {churches.length > 0 ? (
          <Fragment>
            <div className="action-drawer mb-1 p-1">
              <h3 className="fs-4 c-tint1 m-0 pr-4p pl-4p">{listTitle}</h3>
            </div>
            <ChurchListContainer>
              {churches.map((church) => (
                <ChurchItem key={church.destination_url} {...church} />
              ))}
            </ChurchListContainer>
          </Fragment>
        ) : (
          <EmptyResultsContainer>
            <div className="d-f fd-c ai-c jc-c p-1">
              <p>
                No results near <strong>{searchTerm}</strong>
              </p>
              <button
                onClick={resetSearchAndFilteredResults}
                className="btn compact-btn secondary-btn"
              >
                Reset search
              </button>
            </div>
          </EmptyResultsContainer>
        )}
      </div>
    )
  }
}

export default class ChurchSearch extends React.Component {
  static propTypes = {
    suggestions: arrayOf(churchProp).isRequired,
    nearby: arrayOf(churchProp).isRequired,
    search: arrayOf(churchProp).isRequired,
    routes: shape({
      churches_url: string.isRequired,
    }).isRequired,
    destination: string,
    text_to_give_activation_code: string,
  }

  constructor(props) {
    super(props)

    this.state = {
      filterTerm: "",
      searchTerm: "",
      isSearching: false,
    }
  }

  componentWillReceiveProps() {
    this.setState({ isLoading: false })
  }

  handleFilterTermChange = (e) => {
    this.setState({ filterTerm: e.target.value })
  }

  handleFormSubmit = (e) => {
    e.preventDefault()
    this.setState({
      isLoading: true,
      isSearching: true,
    })
  }

  updateSearchTerm = (e) => {
    e.preventDefault()
    this.setState({ searchTerm: e.target.value })
  }

  clearFilterTerm = (e) => {
    e.preventDefault()
    this.setState({
      filterTerm: "",
    })
  }

  resetSearchAndFilteredResults = () => {
    this.setState({
      isSearching: false,
      searchTerm: "",
      filterTerm: "",
    })
  }

  filterChurches = (churches) => {
    const { filterTerm } = this.state
    const fuzzySearch = new RegExp(filterTerm.replace(/[^\w]/g, "").split("").join(".*"), "gi")
    return churches.filter((church) => church.name.match(fuzzySearch))
  }

  getHasFilteredSuggestionsAndNearby = () => {
    return (
      this.filterChurches(this.props.suggestions).length > 0 ||
      this.filterChurches(this.props.nearby).length > 0
    )
  }

  renderSearchForm = (url) => {
    const { searchTerm } = this.state
    const { destination, text_to_give_activation_code } = this.props

    return (
      <form action={url} method="get" onSubmit={this.handleFormSubmit} data-remote>
        <div className="d-b mb-1 mt-2">
          <label className="fs-4" htmlFor="church_search">
            Address, city or postal code
          </label>
          <div className="d-f fd-c fd-r@xs">
            <div className="w-100% w-a@xs f-1@xs mr-0 mr-1@sm p-r">
              <input
                autoFocus
                name="search"
                id="church_search"
                type="text"
                placeholder="Enter an address, city, or postal code."
                style={{ height: "40px" }}
                value={searchTerm}
                onChange={this.updateSearchTerm}
              />
            </div>
            <button
              name="commit"
              type="submit"
              className="btn btn--primary w-100% w-a@xs ml-1@xs mt-2 mt-0@xs"
              disabled={searchTerm === "" && "disabled"}
            >
              Search
            </button>
          </div>
        </div>

        <input type="hidden" name="destination" value={destination} />
        <input
          type="hidden"
          name="text_to_give_activation_code"
          value={text_to_give_activation_code}
        />
      </form>
    )
  }

  renderSuggestionsAndNearby = () => {
    const suggestions = this.filterChurches(this.props.suggestions)
    const nearby = this.filterChurches(this.props.nearby)

    return suggestions.length > 0 || nearby.length > 0 ? (
      <Fragment>
        <ChurchList listTitle="Suggested" churches={suggestions} showEmptyResults={false} />

        <ChurchList listTitle="Nearby" churches={nearby} showEmptyResults={false} />
      </Fragment>
    ) : (
      <EmptyResultsContainer>
        No results nearby. Try adding a city and state or postal code.
      </EmptyResultsContainer>
    )
  }

  render() {
    const { filterTerm } = this.state

    return (
      <Fragment>
        <div className="d-b mb-3">
          <h1 className="d-b mb-4p">Text2Give Setup</h1>
          <h2 className="d-b mb-0 fs-2 c-tint3">Find Your Church</h2>
        </div>
        <label className="fs-4" htmlFor="church_name_filter">
          Church name (optional)
        </label>
        <div className="mb-1 p-r">
          <input
            type="text"
            id="church_name_filter"
            placeholder="Enter church name (optional)"
            value={filterTerm}
            onChange={this.handleFilterTermChange}
            style={{ height: "40px" }}
            ref={(r) => (this.input = r)}
          />

          {filterTerm !== "" && <ClearButton onClick={this.clearFilterTerm} />}
        </div>

        {this.renderSearchForm(this.props.routes.churches_url)}

        {this.state.isSearching
          ? !this.state.isLoading && (
              <ChurchList
                listTitle="Search Results"
                churches={this.filterChurches(this.props.search)}
                showEmptyResults={true}
                searchTerm={this.state.searchTerm}
                resetSearchAndFilteredResults={() => this.resetSearchAndFilteredResults()}
              />
            )
          : this.renderSuggestionsAndNearby()}
      </Fragment>
    )
  }
}
