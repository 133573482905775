import React from "react"
import StripeWithGuard from "shared/runners/stripe_with_guard"
import { designationsAmountCents } from "church_center/utils/designations"
import coverTheFee from "church_center/utils/cover_the_fee"
import MoneyInput from "shared/components/money_input"
import { featureEnabled } from "shared/runners/flipper"
import { arrayOf, func, number, oneOfType, shape, string, bool } from "prop-types"

const buttonHeight = "46px"

export default class ApplePayButton extends React.Component {
  static propTypes = {
    country: string.isRequired,
    currency: string.isRequired,
    designations: arrayOf(
      shape({
        name: string.isRequired,
        amount: oneOfType([string, number]).isRequired,
      })
    ),
    manualLineItems: arrayOf(
      shape({
        label: string.isRequired,
        type: string.isRequired,
        amount: string.isRequired,
      })
    ),
    manualTotalAmount: string,
    organizationName: string.isRequired,
    description: string,
    onFailure: func,
    onCancel: func,
    onSuccess: func.isRequired,
    onClick: func,
    validate: func,
    feeCoverageAllowed: bool.isRequired,
    isCoveringFee: bool.isRequired,
    onChangeCoveringFee: func.isRequired,
  }

  static defaultProps = {
    validate: () => ({ error: null }),
    onClick: () => {},
    onCancel: () => {},
    onFailure: (error) => {
      window.Bugsnag.notifyException(error, "ApplePayButton")
      alert(`Apple Pay: ${error.message}`)
    },
  }

  state = { available: false }

  componentDidMount() {
    StripeWithGuard.promise().then(() => {
      this.paymentRequest = StripeWithGuard.instance().paymentRequest(
        mapPropsToPaymentRequestArgument(this.props)
      )

      this.paymentRequest.on("cancel", this.handlePaymentRequestCancel.bind(this))
      this.paymentRequest.on("error", this.handlePaymentRequestError.bind(this))
      this.paymentRequest.on("paymentmethod", this.handlePaymentRequestPaymentMethod.bind(this))

      this.paymentRequest
        .canMakePayment()
        .then(this.conditionallyMountPaymentRequestButton.bind(this))
    })
  }

  componentWillUnmount() {
    this.stripeElement && this.stripeElement.unmount()
    this.stripeElement = null
  }

  handlePaymentRequestCancel() {
    this.props.onCancel()
  }
  handlePaymentRequestError(event) {
    this.props.onFailure(event)
  }

  handlePaymentRequestPaymentMethod({ complete, ...result }) {
    this.props.onSuccess(result, complete)
  }

  handlePaymentRequestButtonClick({ preventDefault }) {
    const { error } = this.props.validate()
    if (error) {
      preventDefault()
      alert(error)
    } else {
      this.props.onClick()

      const { total, displayItems } = mapPropsToPaymentRequestArgument(this.props)
      this.paymentRequest.update({ total, displayItems })
    }
  }

  conditionallyMountPaymentRequestButton(result) {
    if (result && result.applePay) {
      const style = { paymentRequestButton: { height: buttonHeight } }
      this.stripeElement = StripeWithGuard.instance().elements().create("paymentRequestButton", {
        paymentRequest: this.paymentRequest,
        style,
      })
      this.stripeElement.on("click", this.handlePaymentRequestButtonClick.bind(this))
      this.stripeElement.mount(this.stripeElementMountPoint)
      this.setState({ available: true })
    } else {
      this.setState({ available: false })
    }
  }

  render() {
    const opacity = this.state.available ? "1" : "0"
    const height = this.state.available ? "auto" : "0px"

    const parentStyle = {
      height,
      marginBottom: this.state.available ? "2rem" : "0",
      marginTop: this.state.available ? "1rem" : "0",
      opacity,
      position: "relative",
      transition: "height 0.6s 0.0s ease-in, margin-bottom 0.6s 0.0s ease-in",
    }

    const displayingOurOwnButtonToWorkaroundJankinessOfIframeLoadStyle = {
      opacity,
      backgroundColor: "#111",
      backgroundImage: "-webkit-named-image(apple-pay-logo-white)",
      backgroundSize: "100% 100%",
      backgroundOrigin: "content-box",
      backgroundRepeat: "no-repeat",
      cursor: "pointer",
      width: "100%",
      height: buttonHeight,
      padding: "12px 0",
      borderRadius: "3px",
      border: "none",
      zIndex: 2,
      position: "absolute",
      top: "0",
      pointerEvents: "none",
      transition: "opacity 0.6s 0.2s ease-in",
    }

    const mountPointStyle = {
      zIndex: 1,
      position: "absolue",
      top: "0",
      height: buttonHeight,
      cursor: "pointer",
      pointerEvents: "all",
      width: "100%",
    }

    const { coveredFeeCents } = coverTheFee.calculate(this.props.designations || [], "card")
    const coveredFeeAmount = MoneyInput.minimalTextValue(coveredFeeCents / 100)

    return (
      <div style={parentStyle}>
        <div>
          {this.props.feeCoverageAllowed && (
            <div
              className="d-f jc-fs my-2 p-r fs-4"
              style={{
                opacity,
                transition: "all ease .5s .2s",
              }}
            >
              <input
                className="checkbox"
                id="covering_fee"
                type="checkbox"
                checked={this.props.isCoveringFee}
                onChange={(event) => {
                  this.props.onChangeCoveringFee(event.target.checked)
                }}
              />
              <label
                className="checkbox-label"
                style={{ color: "#313437", height: "auto" }}
                htmlFor="covering_fee"
              >
                {`Add $${coveredFeeAmount} to cover the processing fee `}
                <div className="fs-5 c-tint3 mt-1">
                  Note: {I18n.t("ACH")} bank transfers only cost $0.
                  {coverTheFee.getFeeRates("bank_account").flat_cents}
                </div>
              </label>
            </div>
          )}
          <div className="p-r">
            <div
              style={mountPointStyle}
              ref={(element) => {
                this.stripeElementMountPoint = element
              }}
            />
            <button
              aria-label="apple pay button"
              style={displayingOurOwnButtonToWorkaroundJankinessOfIframeLoadStyle}
            />
          </div>
        </div>
      </div>
    )
  }
}

function mapPropsToPaymentRequestArgument(props) {
  const { country, currency } = props
  const total = mapPropsToPaymentRequestTotal(props)
  const displayItems = mapPropsToPaymentRequestDisplayItems(props)
  return {
    country: country.toUpperCase(),
    currency: currency.toLowerCase(),
    total,
    displayItems,
  }
}

function mapPropsToPaymentRequestDisplayItems(props) {
  const { designations, isCoveringFee, description: label, manualLineItems } = props
  let selectedDesignations

  if (manualLineItems) {
    return manualLineItems.map((designationish) => {
      const amount = designationsAmountCents([designationish])
      return { ...designationish, amount }
    })
  }

  if (isCoveringFee) {
    const { designationsCoveringFee } = coverTheFee.calculate(designations, "card")
    selectedDesignations = designationsCoveringFee
  } else {
    selectedDesignations = designations
  }

  const lineItems = selectedDesignations.map((designation) => ({
    label: `${designation.name} Fund`,
    amount: designationsAmountCents([designation]),
  }))

  if (label) {
    lineItems.push({ label, type: "pending", amount: 0 })
  }

  return lineItems
}

function mapPropsToPaymentRequestTotal(props) {
  const { designations, manualTotalAmount, isCoveringFee, organizationName: label } = props
  let amount

  if (manualTotalAmount) {
    amount = designationsAmountCents([{ amount: manualTotalAmount }])
  } else if (isCoveringFee) {
    const { designationsCoveringFee } = coverTheFee.calculate(designations, "card")
    amount = designationsAmountCents(designationsCoveringFee)
  } else {
    amount = designationsAmountCents(designations)
  }

  return { amount, label }
}
