import React from "react"
import { string, func, bool } from "prop-types"
import PaymentMethodIcon from "church_center/components/find_payment_method/payment_method_icon"
import Ladda from "shared/components/ladda"
import _ from "lodash"
import { css } from "glamor"
import colors from "church_center/utils/colors"
import Icon from "church_center/components/external_icon"
import InlineSuccessMessage from "church_center/components/inline_success_message"

const PaymentMethodChoice = ({
  icon,
  primary,
  secondary,
  onSelect,
  onCancel,
  loading,
  selected,
  href = "",
  remote = false,
  badge,
  successMessage,
}) => {
  const styles = {
    button: css({
      alignItems: "center",
      backgroundColor: colors.tint9,
      borderRadius: 4,
      border: 0,
      display: "flex",
      justifyContent: "space-between",
      marginBottom: selected ? 0 : 8,
      padding: "12px 16px",
      width: "100%",
      transition: "all 0.2s",
      position: "relative",

      "& .chevron": {
        color: colors.tint4,
      },

      "&:hover": {
        backgroundColor: selected ? colors.tint9 : colors.tint8,
        cursor: selected ? "auto" : "pointer",
        "& .chevron": { color: colors.tint3 },
      },
    }),
  }

  const ClickableElement = href ? "a" : onCancel ? "div" : "button"
  const clickProps = href ? { href, "data-remote": remote } : { onClick: onSelect || _.noop }

  return (
    <ClickableElement
      {...clickProps}
      data-cy="payment_method_choice_button"
      {...styles.button}
      tabIndex={selected ? "-1" : "0"}
    >
      <div className={`${selected ? "fw-500" : "fw-400"} fs-4 d-f ai-c`}>
        <div className="mr-2">
          <PaymentMethodIcon name={icon} />
        </div>
        <span className="fs-4 c-tint0 mr-4p">{primary}</span>
        {secondary && <span className="fs-4 c-tint0">{secondary}</span>}
        {badge && <div className="badge xs-badge gc-ruby c-tint10 fw-500">{badge}</div>}
        {successMessage && (
          <InlineSuccessMessage message={successMessage} className="p-s ml-1 tf-n" />
        )}
      </div>
      <div>
        {onCancel && (
          <button
            className="d-f ai-c stripped-btn p-1 fs-4 c-tint3 clickable-icon"
            style={{ marginRight: -8, borderRadius: 4 }}
            onClick={onCancel}
            aria-label="cancel payment method option"
          >
            <span className="p-r t-2p pr-2p">
              <Icon symbol="general#left-chevron" aria-hidden />
            </span>
            Cancel
          </button>
        )}
        {onSelect && (
          <Ladda loading={loading || false}>
            <span />
          </Ladda>
        )}
        <span className={`chevron ${onCancel && "d-n"}`}>
          <Icon symbol="general#right-chevron" aria-hidden />
        </span>
      </div>
    </ClickableElement>
  )
}

PaymentMethodChoice.propTypes = {
  icon: string.isRequired,
  primary: string.isRequired,
  secondary: string,
  onSelect: func,
  onCancel: func,
  loading: bool,
  selected: bool,
  href: string,
  remote: bool,
  badge: string,
  successMessage: string,
}

export default PaymentMethodChoice
