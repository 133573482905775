// This file serves as a manifest for "Top level components". What does that mean? That means
// components that can be mounted from the Rails views using the `react_component` or
// `mount_react_component` helpers. If you're creating a new hand-off from Rails to React,
// export that component from this manifest!

export BackgroundDonationStatus from "church_center/components/background_donation_status"
export BackgroundDonationStatusForCCApp from "church_center/components/background_donation_status_for_cc_app"
export BarChart from "church_center/components/simplified_charts/bar_chart"
export ChurchSearch from "church_center/components/church_search"
export DonationForm from "church_center/components/donation_form"
export DonationHistoryFilter from "church_center/components/donation_history_filter"
export EditSchedule from "church_center/components/edit_schedule"
export PaymentMethodForm from "church_center/components/payment_method_form"
export RevealMoreChildren from "church_center/components/reveal_more_children"
export TextToGiveActivationForm from "church_center/components/text_to_give_activation_form"
export { SelfHydratingHeader } from "@planningcenter/cc-nav"
export WaysToGive from "church_center/components/ways_to_give"
export GivingProfileSidebar from "church_center/components/giving_profile_sidebar"

export default exports
