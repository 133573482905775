import React from "react"
import OccurrenceApi from "church_center/api/occurrence"
import i18n from "shared/runners/i18n"
import moment from "moment"
import { object, string } from "prop-types"

export default class NextDonation extends React.Component {
  static propTypes = {
    schedule: object.isRequired,
    start_date: string.isRequired,
    next_occurrence: string,
  }

  constructor(props) {
    super(props)
    const { next_occurrence } = props
    this.state = { next_occurrence }
  }

  componentDidMount() {
    this.refreshNextOccurrence(this.props)
  }

  componentWillReceiveProps(nextProps) {
    this.refreshNextOccurrence(nextProps)
  }

  refreshNextOccurrence = ({ schedule, start_date }) => {
    OccurrenceApi.index({
      start_date,
      schedule: JSON.stringify(schedule),
      limit: 1,
    }).then(
      ({ next_occurrences }) => this.setState({ next_occurrence: next_occurrences[0] }),
      () => this.setState({ next_occurrence: null })
    )
  }

  render() {
    if (!this.state.next_occurrence) {
      return <span>"..."</span>
    }

    return <span>{moment(this.state.next_occurrence).format(i18n.dateFormatMoment)}.</span>
  }
}
